import React from "react"

export const ArrowUp = () => {
  return (
    <svg width={16} viewBox="0 0 16 16">
      <path
        style={{
          fill: "none",
          stroke: "#d16539",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
        }}
        d="M12 23.25V.75"
        transform="scale(.66667)"
      />
      <path
        style={{
          fill: "none",
          stroke: "#d16539",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
        }}
        d="M22.5 11.25 12 .75 1.5 11.25"
        transform="scale(.66667)"
      />
    </svg>
  )
}
