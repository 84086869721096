import React from "react"

const HomeLink = () => {
  return (
    <h2 className="text-primary-500 px-2 md:px-4 mr-auto hidden sm:flex">
      <a href="/#hero">
        <span
          style={{ fontFeatureSettings: "'salt' 1" }}
          className="text-black"
        >
          A
        </span>
        &
        <span
          style={{ fontFeatureSettings: "'salt' 1" }}
          className="text-black ml-1"
        >
          D
        </span>
      </a>
    </h2>
  )
}

export const Nav = () => {
  return (
    <nav className="sticky top-0 bg-white w-full flex justify-center items-center z-10 shadow md:px-4 pr-2 text-sm md:text-base">
      <HomeLink />
      <li key="events" className="list-none m-0 md:px-8 pr-3 sm:pr-4 pl-2 py-4">
        <a
          style={{ fontFeatureSettings: "'salt' 3" }}
          className="font-serif"
          href="/#events"
        >
          It's all happening
        </a>
      </li>
      <li key="about" className="list-none m-0 md:px-8 px-2 py-4">
        <a
          style={{ fontFeatureSettings: "'salt' 3" }}
          className="font-serif"
          href="/about"
        >
          Our Story
        </a>
      </li>
      <li
        key="registry"
        className="list-none m-0 md:px-8 px-2 pl-3 sm:pl-4 py-4"
      >
        <a
          style={{ fontFeatureSettings: "'salt' 3" }}
          className="font-serif"
          href="/registry"
        >
          Registry
        </a>
      </li>
      <li key="rsvp" className="list-none m-0 ml-auto">
        <a
          style={{ fontFeatureSettings: "'salt' 3" }}
          className="font-serif bg-primary-500 px-4 py-2 text-white"
          href="/#rsvp"
        >
          Are you in?
        </a>
      </li>
    </nav>
  )
}
