import * as React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import "../fonts/fonts.css"
import { ArrowUp } from "./icons"
import { Nav } from "./Nav"

const Layout = ({ children }) => {
  return (
    <div className="h-full">
      <Nav />
      <main className="flex flex-col min-h-full">{children}</main>
      <div className="md:py-8 m-auto w-full flex justify-center">
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="flex items-center p-4 my-4"
          href="/#hero"
        >
          <span className="mr-2">Back to top</span>
          <ArrowUp />
        </button>
      </div>
      <footer className="max-w-screen px-2 py-4 md:py-8 shadow mt-auto">
        <div className="max-w-2xl m-auto flex justify-center">
          <p className="text-gray-600 text-base">#firstdayofmorrislife</p>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
